import React from 'react';
import {
  useMutation,
  useQuery,
  UseQueryResult,
  UseMutationResult,
} from '@tanstack/react-query';
import { Group } from '@/types/groups';
import { GENERAL_ERROR } from '@/utils/constants';
import client from '@/utils/api';
import { useAuth } from './auth';
import { Callout, Snackbar } from '@purposeinplay/core-v2';
import { useToast } from '@purposeinplay/utils';
import { useTranslation } from '@/app/i18n/client';
import { useOtherToastMessages } from '@/hooks/use-other-toast-messages';

export const useGetLootboxes = (enabled = true): UseQueryResult<Group[]> => {
  const { user } = useAuth();

  return useQuery({
    queryKey: ['playerLootboxes'],
    queryFn: () => client('player/lootboxes'),
    enabled: enabled && !user?.isLoading && user?.isAuthenticated,
  });
};

export const useSpinWheel = (): UseMutationResult => {
  const { t } = useTranslation();
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  return useMutation({
    mutationFn: (data: any) => {
      const { id } = data;
      return client(`player/lootboxes/${id}/activation`, 'POST');
    },
    onError: (e) => {
      toast({
        state: 'error',
        title: toastMessages?.general_error,
      });
    },
  });
};
