import { useEffect } from 'react';
import { Group } from '@/types/groups';
import { usePlayerCompPoints } from './comp-points';
import { useState } from 'react';
import { useGroups } from './groups';
import { Level, useLoyaltyLevel } from './loyalty-level';

export const useLoyalty = (): {
  currentLevel: Level | null;
  isLoading: boolean;
} => {
  const [userTier, setUserTier] = useState<Group>();
  const { data: groups = [], isLoading: groupsLoading } = useGroups();
  const { isLoading: compPointsLoading } = usePlayerCompPoints();

  // UNCOMMENT TO TEST

  // const compPoints = {
  //   persistent: {
  //     points: 1,
  //   },
  // };

  const loyaltyGroups = groups.filter((group) =>
    group.id.includes('loyalty:lvl'),
  );

  const { currentLevel } = useLoyaltyLevel();

  useEffect(() => {
    const tier = loyaltyGroups?.find((group) => {
      return (
        group.id.includes('loyalty:lvl') &&
        Number(group.id.split('loyalty:lvl')[1]) ===
          Number(currentLevel?.level) + 1
      );
    });

    const nextLevel = Number(currentLevel?.nextLevel?.split('Level ')[1]);
    const currLevel = Number(userTier?.name.split('Level ')[1]);

    if (currentLevel?.neededUntilNextLvl === 0 && nextLevel !== currLevel) {
      setUserTier(tier);
    }
  }, [
    currentLevel?.level,
    currentLevel?.neededUntilNextLvl,
    currentLevel?.nextLevel,
    loyaltyGroups,
    userTier?.id,
    userTier?.name,
  ]);

  return { currentLevel, isLoading: groupsLoading || compPointsLoading };
};
