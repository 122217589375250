'use client';

import { usePathname } from 'next/navigation';
import { useMemo } from 'react';

export const useIsOnGamePage = (): boolean => {
  const pathname = usePathname();

  const isOnPage = useMemo(() => {
    return pathname.includes('/games/') && !EXCLUDED_PAGES.includes(pathname);
  }, [pathname]);

  return isOnPage;
};

export default useIsOnGamePage;

const EXCLUDED_PAGES = ['/games/favorites', '/games/recent'];
