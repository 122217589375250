export const WHEEL_PRIZES_UPDATED = [
  {
    id: 'WoF $50 Bonus',
    deg: 0,
  },
  {
    id: 'WoF 30 Free Spins',
    deg: 30,
  },
  {
    id: 'WoF 10 Free Spins',
    deg: 60,
  },
  {
    id: 'WoF $1,000 Bonus',
    deg: 90,
  },
  {
    id: 'WoF 10 Free Spins',
    deg: 120,
  },
  {
    id: 'WoF $10 Bonus',
    deg: 150,
  },
  {
    id: 'WoF $5 Bonus + 20 Free Spins',
    deg: 180,
  },
  {
    id: 'WoF $2 Bonus',
    deg: 210,
  },
  {
    id: 'WoF 20 Free Spins',
    deg: 240,
  },
  {
    id: 'WoF $100 Bonus',
    deg: 270,
  },
  {
    id: 'WoF 15 Free Spins',
    deg: 300,
  },
  {
    id: 'WoF $2 Bonus + 10 Free Spins',
    deg: 330,
  },
];
export const JUNGLE_WHEEL_PRIZES = [
  {
    id: 'Jungle Wheel $10 Bonus',
    deg: 0,
  },
  {
    id: 'Jungle Wheel 50 Free Spins',
    deg: 30,
  },
  {
    id: 'Jungle Wheel 25 Free Spins',
    deg: 60,
  },
  {
    id: 'Jungle Wheel $10,000 Bonus',
    deg: 90,
  },
  {
    id: 'Jungle Wheel $500 Bonus',
    deg: 120,
  },
  {
    id: 'Jungle Wheel $250 Bonus',
    deg: 150,
  },
  {
    id: 'Jungle Wheel $50 Bonus + 100 Free Spins',
    deg: 180,
  },
  {
    id: 'Jungle Wheel $50 Bonus',
    deg: 210,
  },
  {
    id: 'Jungle Wheel 150 Free Spins',
    deg: 240,
  },
  {
    id: 'Jungle Wheel $10 Bonus + 25 Free Spins',
    deg: 270,
  },
  {
    id: 'Jungle Wheel 100 Free Spins',
    deg: 300,
  },
  {
    id: 'Jungle Wheel 75 Free Spins',
    deg: 330,
  },
];
export const VIP_WHEEL_PRIZES = [
  {
    id: 'VIP Wheel $50 Bonus',
    deg: 0,
  },
  {
    id: 'VIP Wheel 50 VIP Free Spins',
    deg: 30,
  },
  {
    id: 'VIP Wheel 25 VIP Free Spins',
    deg: 60,
  },
  {
    id: 'VIP Wheel $50,000 Bonus',
    deg: 90,
  },
  {
    id: 'VIP Wheel $2,000 Bonus',
    deg: 120,
  },
  {
    id: 'VIP Wheel 500 VIP Free Spins',
    deg: 150,
  },
  {
    id: 'VIP Wheel $200 Bonus + 100 VIP Free Spins',
    deg: 180,
  },
  {
    id: 'VIP Wheel $200 Bonus',
    deg: 210,
  },
  {
    id: 'VIP Wheel 150 VIP Free Spins',
    deg: 240,
  },
  {
    id: 'VIP Wheel $50 Bonus + 50 VIP Free Spins',
    deg: 270,
  },
  {
    id: 'VIP Wheel $100 Bonus',
    deg: 300,
  },
  {
    id: 'VIP Wheel 75 VIP Free Spins',
    deg: 330,
  },
];

export const ALL_PRIZES = [
  ...JUNGLE_WHEEL_PRIZES,
  ...VIP_WHEEL_PRIZES,
  ...WHEEL_PRIZES_UPDATED,
];
