import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
} from 'react';
import { FilteredGame } from '@/types/games_filters';
import { getPlayURL } from '@/utils/games';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import {
  setGameViewAt,
  setIsFunForGameView,
  setViewFiat,
} from '@/app/reducers/bootstrap';
import useWalletSelector from '@/hooks/use-wallet-selector';

export type ScreenLayout = 'fullscreen' | 'single' | 'double' | 'quad';
export type GameStatus =
  | 'loading'
  | 'fiat-play'
  | 'no-currency'
  | 'restricted'
  | 'real-only'
  | 'closed';

interface SecondaryGame {
  game: FilteredGame | null;
  url: string | null;
  isFun: boolean;
  isGameViewOpen: boolean;
  status: GameStatus | null;
  viewFiat: boolean;
}

const defaultSecondaryGame: SecondaryGame = {
  game: null,
  url: null,
  isFun: false,
  isGameViewOpen: true,
  status: null,
  viewFiat: false,
};

interface ScreenLayoutContextType {
  layout: ScreenLayout;
  setLayout: (layout: ScreenLayout) => void;
  isFullScreen: boolean;
  setIsFullScreen: (isFullScreen: boolean) => void;
  secondaryGames: {
    [key: number]: SecondaryGame;
  };
  setGameForIndex: (
    index: number,
    game: FilteredGame | null,
    currency?: string,
    isFunMode?: boolean,
  ) => void;
  getGameForIndex: (index: number) => SecondaryGame;
  clearGameForIndex: (index: number) => void;
  toggleIsFunForIndex: (index: number, realCurrency?: string) => void;
  toggleViewFiatForIndex: (index: number, viewFiat: boolean) => void;
  setGameViewOpenForIndex: (index: number, isOpen: boolean) => void;
  setGameStatusForIndex: (index: number, status: GameStatus | null) => void;
}

const defaultContextValue: ScreenLayoutContextType = {
  layout: 'single',
  setLayout: () => {
    console.warn(
      'ScreenLayoutProvider not found. Using default no-op setLayout function.',
    );
  },
  isFullScreen: false,
  setIsFullScreen: () => {
    console.warn(
      'ScreenLayoutProvider not found. Using default no-op setIsFullScreen function.',
    );
  },
  secondaryGames: {},
  setGameForIndex: () => {
    console.warn(
      'ScreenLayoutProvider not found. Using default no-op setGameForIndex function.',
    );
  },
  getGameForIndex: () => defaultSecondaryGame,
  clearGameForIndex: () => {
    console.warn(
      'ScreenLayoutProvider not found. Using default no-op clearGameForIndex function.',
    );
  },
  toggleIsFunForIndex: () => {
    console.warn(
      'ScreenLayoutProvider not found. Using default no-op toggleIsFunForIndex function.',
    );
  },
  toggleViewFiatForIndex: () => {
    console.warn(
      'ScreenLayoutProvider not found. Using default no-op toggleViewFiatForIndex function.',
    );
  },
  setGameViewOpenForIndex: () => {
    console.warn(
      'ScreenLayoutProvider not found. Using default no-op setGameViewOpenForIndex function.',
    );
  },
  setGameStatusForIndex: () => {
    console.warn(
      'ScreenLayoutProvider not found. Using default no-op setGameStatusForIndex function.',
    );
  },
};

const ScreenLayoutContext =
  createContext<ScreenLayoutContextType>(defaultContextValue);

export const ScreenLayoutProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [layout, setLayout] = useState<ScreenLayout>('single');
  const [isFullScreen, setIsFullScreen] = useState(false);
  const dispatch = useAppDispatch();
  const { account } = useWalletSelector();
  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);

  const [secondaryGames, setSecondaryGames] = useState<{
    [key: number]: SecondaryGame;
  }>({});

  const getGameForIndex = useCallback(
    (index: number): SecondaryGame => {
      return secondaryGames[index] || defaultSecondaryGame;
    },
    [secondaryGames],
  );

  const clearGameForIndex = useCallback(
    (index: number) => {
      setSecondaryGames((prev) => {
        const newGames = { ...prev };
        delete newGames[index];
        return newGames;
      });

      if (index > 0) {
        dispatch(setGameViewAt({ index, gameView: null }));
      }
    },
    [dispatch],
  );

  const setGameForIndex = useCallback(
    (
      index: number,
      game: FilteredGame | null,
      currency: string = 'USD',
      isFunMode?: boolean,
    ) => {
      if (!game) {
        clearGameForIndex(index);
        return;
      }

      let url: string = '';

      if (viewFiat) {
        url = getPlayURL(game, 'USD') || '';
      } else {
        url = getPlayURL(game, currency) || '';
      }

      const isFun = isFunMode !== undefined ? isFunMode : currency === 'FUN';

      setSecondaryGames((prev) => ({
        ...prev,
        [index]: {
          game,
          url,
          isFun,
          isGameViewOpen: true,
          status: null,
          viewFiat,
        },
      }));

      if (index > 0) {
        dispatch(
          setGameViewAt({
            index,
            gameView: {
              ...game,
              url,
              isFun,
            },
          }),
        );

        dispatch(
          setIsFunForGameView({
            index,
            isFun,
          }),
        );
      } else if (index === 0) {
        dispatch(setViewFiat(viewFiat));
      }
    },
    [viewFiat, clearGameForIndex, dispatch],
  );

  const toggleIsFunForIndex = useCallback(
    (index: number, realCurrency: string = 'USD') => {
      setSecondaryGames((prev) => {
        if (!prev[index]) return prev;

        const currentGame = prev[index];
        const newIsFun = !currentGame.isFun;

        const currency = newIsFun ? 'FUN' : realCurrency;
        const url = getPlayURL(currentGame.game, currency) as string;

        const viewFiat = !newIsFun && realCurrency === 'USD';

        return {
          ...prev,
          [index]: {
            ...currentGame,
            isFun: newIsFun,
            url,
            viewFiat,
          },
        };
      });

      if (index === 0) {
        const currentGame = secondaryGames[index] || defaultSecondaryGame;
        const newIsFun = !currentGame.isFun;

        if (!newIsFun && realCurrency === 'USD') {
          dispatch(setViewFiat(true));
        } else if (newIsFun) {
          dispatch(setViewFiat(false));
        }
      }
    },
    [dispatch, secondaryGames],
  );

  const toggleViewFiatForIndex = useCallback(
    (index: number, viewFiat: boolean) => {
      setSecondaryGames((prev) => {
        if (!prev[index]) return prev;

        const currentGame = prev[index];

        if (currentGame.isFun && viewFiat) {
          return prev;
        }

        let currency;
        if (viewFiat) {
          currency = 'USD';
        } else {
          currency = account?.currency || 'BTC';
        }

        const url =
          !currentGame.isFun && currentGame.game
            ? (getPlayURL(currentGame.game, currency) as string)
            : currentGame.url || '';

        return {
          ...prev,
          [index]: {
            ...currentGame,
            viewFiat,
            url: currentGame.isFun ? currentGame.url || '' : url,
          },
        };
      });

      if (index === 0) {
        dispatch(setViewFiat(viewFiat));
      } else if (index > 0) {
        const currentGame = secondaryGames[index];
        if (currentGame && currentGame.game) {
          dispatch(
            setGameViewAt({
              index,
              gameView: {
                ...currentGame.game,
                url: currentGame.url || '',
                isFun: currentGame.isFun,
              },
            }),
          );
        }
      }
    },
    [dispatch, secondaryGames, account],
  );

  const setGameViewOpenForIndex = useCallback(
    (index: number, isOpen: boolean) => {
      setSecondaryGames((prev) => {
        if (!prev[index]) return prev;

        return {
          ...prev,
          [index]: {
            ...prev[index],
            isGameViewOpen: isOpen,
          },
        };
      });
    },
    [],
  );

  const setGameStatusForIndex = useCallback(
    (index: number, status: GameStatus | null) => {
      setSecondaryGames((prev) => {
        if (!prev[index]) return prev;

        return {
          ...prev,
          [index]: {
            ...prev[index],
            status,
          },
        };
      });
    },
    [],
  );

  return (
    <ScreenLayoutContext.Provider
      value={{
        layout,
        setLayout,
        isFullScreen,
        setIsFullScreen,
        secondaryGames,
        setGameForIndex,
        getGameForIndex,
        clearGameForIndex,
        toggleIsFunForIndex,
        toggleViewFiatForIndex,
        setGameViewOpenForIndex,
        setGameStatusForIndex,
      }}
    >
      {children}
    </ScreenLayoutContext.Provider>
  );
};

export const useScreenLayout = (): ScreenLayoutContextType => {
  const context = useContext(ScreenLayoutContext);
  return context;
};
