'use client';
import React, { Suspense, createContext, useMemo, useState } from 'react';

import {
  EventPublicWinsPayload,
  BonusPayload,
  PaymentPayload,
  EventAnalyticsPayload,
  FreeSpinsPayload,
  GroupPayload,
  LootboxesPayload,
} from '@/types/realtime';
import usePlayerNotifications from '@/hooks/use-player-notifications';
import { useRealtime } from './realtime';
import { openModal } from '@/hooks/modal';
import { useLocalStorage } from 'usehooks-ts';
import { useWofQuery } from '@/generated';
import { useNotificationsToasts } from '@/modules/feed/hooks/use-notifications-toasts';

type Data =
  | EventPublicWinsPayload
  | EventAnalyticsPayload
  | BonusPayload
  | PaymentPayload
  | FreeSpinsPayload
  | GroupPayload
  | LootboxesPayload;

type NotificationsContextType = {
  unreadNotifications: any[];
  setNotificationsDetailsId: any;
  setOpenNotifications: any;
  openNotifications: boolean;
  notificationDetailsId: any;
  setUnreadNotifications: any;
  notifications: any;
  notificationDetails: any;
  setNotificationDetails: any;
  isLoading: boolean;
};

export const NotificationsContext = createContext<NotificationsContextType>({
  unreadNotifications: [],
  notifications: [],
  isLoading: false,
  setNotificationsDetailsId: undefined,
  setOpenNotifications: undefined,
  openNotifications: false,
  notificationDetailsId: undefined,
  setUnreadNotifications: undefined,
  notificationDetails: undefined,
  setNotificationDetails: undefined,
});

export const NotificationsWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <NotificationsProvider>{children}</NotificationsProvider>;
};

const NotificationsProvider = ({ children }: { children: React.ReactNode }) => {
  const { isLoading } = useRealtime();
  const [unreadNotifications, setUnreadNotifications] = useLocalStorage(
    'notifications',
    {},
  ) as any;
  const [notificationDetailsId, setNotificationsDetailsId] = useState('');
  const [notificationDetails, setNotificationDetails] = useState(null);
  const [openNotifications, setOpenNotifications] = useState(false);
  const { data: wofItems, isPending: pendingWof } = useWofQuery();

  useNotificationsToasts(
    unreadNotifications,
    setUnreadNotifications,
    (notif, type) => {
      if (type === 'lootboxes') {
        const foundWOF = wofItems?.wheelOfFortunes?.data?.find(
          (wof) => wof?.attributes?.exchange_group_key === notif?.group_key,
        )?.attributes;
        openModal(`${foundWOF?.slug}-wheel`);
        setNotificationDetails({ ...notif, type });
        return;
      }
      setNotificationDetails({ ...notif, type });
    },
  );

  const { isLoading: isNotificationsLoading, notifications = [] } =
    usePlayerNotifications(
      unreadNotifications,
      setUnreadNotifications,
      openNotifications,
    );

  const memoValue = useMemo(() => {
    return {
      unreadNotifications,
      setNotificationsDetailsId,
      setOpenNotifications,
      openNotifications,
      notificationDetailsId,
      notificationDetails,
      setNotificationDetails,
      setUnreadNotifications,
      notifications,
      isLoading: isNotificationsLoading || isLoading,
    };
  }, [
    isLoading,
    isNotificationsLoading,
    notificationDetails,
    notificationDetailsId,
    notifications,
    openNotifications,
    setUnreadNotifications,
    unreadNotifications,
  ]);

  return (
    <NotificationsContext.Provider value={memoValue}>
      {children}
    </NotificationsContext.Provider>
  );
};

const useAllNotifications = () => {
  const context = React.useContext(NotificationsContext);
  if (context === undefined) {
    throw new Error(
      `useAllNotifications must be used within a NotificationsProvider`,
    );
  }
  return context;
};

export default NotificationsProvider;

export { useAllNotifications };
