import Image from 'next/image';

export const fallbackLng = 'en';
export const languages = [fallbackLng];
export const defaultNS = 'translation';
export const cookieName = 'i18next';
export const FULL_LANGS = [
  {
    key: 'en-lang',
    id: 'en',
    label: 'English',
    leadingIcon: (
      <div className="relative h-6 w-6">
        <Image
          src="/assets/flags/en.png"
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 100vw"
          fill
          alt="English Flag"
        />
      </div>
    ),
  },
  {
    key: 'de-lang',
    id: 'de',
    label: 'German',
    leadingIcon: (
      <div className="relative h-6 w-6">
        <Image
          src="/assets/flags/de.png"
          alt="German Flag"
          fill
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 100vw"
        />
      </div>
    ),
  },
];

export const i18nConfig = {
  locales: languages,
  defaultLocale: 'en',
  localeCookie: 'i18next',
  prefixDefault: undefined,
};

export function getOptions(
  lng = fallbackLng,
  ns: string | string[] = defaultNS,
) {
  return {
    // debug: true,
    supportedLngs: languages,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
    returnObjects: true,
    // backend: {
    //   projectId: '01b2e5e8-6243-47d1-b36f-963dbb8bcae3'
    // }
  };
}
