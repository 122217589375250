import { useEffect, useMemo, useReducer } from 'react';
import { useAuth } from '@/hooks/auth';
import { useRealtime } from '@/app/providers/realtime';
import dayjs from 'dayjs';
import { useCurrentModal } from './modal';
import { useLoyalty } from './loyalty';
import { usePlayerBonuses, usePlayerFreeSpins } from './bonuses';
import { useGetLootboxes } from './lootboxes';
import { usePlayerPayments } from './payments';
import { processUnreadNotification } from '@/modules/feed/utils';

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'add_unread': {
      const channelMessages = state[action.channel];
      const existingMessage = channelMessages?.find(
        (item: any) => item.uid === action.payload.uid,
      );

      if (existingMessage) {
        return state;
      }
      return {
        ...state,
        [action.channel]: [...channelMessages, action.payload],
      };
    }
    default:
      throw new Error('Unknown action: ' + action.type);
  }
}

function isNotificationRead(
  id: string,
  unreadNotifications: string[],
): boolean {
  return unreadNotifications?.includes(id);
}

function mapFreeSpinNotification(
  notification: any,
  unreadNotifications: string[],
): any {
  return {
    ...notification,
    type: 'freeSpins',
    read: !isNotificationRead(
      `freeSpins-${notification.id}`,
      unreadNotifications,
    ),
  };
}

function mapPaymentNotification(
  notification: any,
  unreadNotifications: string[],
): any {
  const type = notification?.action === 'deposit' ? 'deposit' : 'withdraw';
  return {
    ...notification,
    type,
    read: !isNotificationRead(
      `${type}-${notification?.id}`,
      unreadNotifications,
    ),
  };
}

function mapLevelNotification(
  notification: any,
  unreadNotifications: string[],
): any {
  return {
    ...notification,
    type: 'level',
    read: !isNotificationRead(`level-${notification?.id}`, unreadNotifications),
  };
}

function mapBonusNotification(
  notification: any,
  unreadNotifications: string[],
): any {
  return {
    ...notification,
    type: 'bonus',
    read: !isNotificationRead(`bonus-${notification?.id}`, unreadNotifications),
  };
}

function mapJackpotNotification(
  notification: any,
  unreadNotifications: string[],
): any {
  return {
    ...notification,
    type: 'jackpot',
    read: !isNotificationRead(
      `jackpot-${notification?.id}`,
      unreadNotifications,
    ),
  };
}

function mapLootboxNotification(
  notification: any,
  unreadNotifications: string[],
): any {
  return {
    ...notification,
    type: 'lootboxes',
    read: !isNotificationRead(
      `lootboxes-${notification?.id}`,
      unreadNotifications,
    ),
  };
}

function filterNotificationsByType(type: string, notifications: any[]): any[] {
  return notifications.filter((notification) => notification?.type === type);
}

function sortNotificationsByCreatedAtDesc(notifications: any[]): any[] {
  return notifications?.sort((a: any, b: any) => {
    if (a?.created_at && b?.created_at) {
      return dayjs(a?.created_at).isAfter(dayjs(b?.created_at)) ? -1 : 1;
    }
    return 1;
  });
}

const cleanupNotifications = (
  notificationsList: string[],
  unreadNotifications: any,
  setUnreadNotifications: any,
  user: any,
  isLoading: boolean,
) => {
  if (isLoading) return;

  const removedNotifs = unreadNotifications?.filter((el: any) => {
    return !notificationsList?.includes(el);
  });

  if (removedNotifs?.length === 0) return;
  // if I find elements which are still in localStorage and are not present in the notifications list
  // that means those elements shouldn't exist in localStorage
  if (removedNotifs?.length > 0) {
    setUnreadNotifications((prev: any) => {
      const prevNotif = prev[user?.currentUser?.id as any] || [];
      return {
        ...prev,
        [user?.currentUser?.id as any]: prevNotif?.filter(
          (notif: any) => !removedNotifs?.includes(notif),
        ),
      };
    });
  }
};

function usePlayerNotifications(
  unreadNotifications: any,
  setUnreadNotifications: any,
  notificationsOpen?: boolean,
) {
  const openNotificationsModal = useCurrentModal() === 'notifications';
  const { currentLevel, isLoading: levelLoading } = useLoyalty();
  const { user } = useAuth();

  const playerData = {
    payments: usePlayerPayments(),
    freeSpins: usePlayerFreeSpins(notificationsOpen),
    bonuses: usePlayerBonuses(notificationsOpen),
    lootboxes: useGetLootboxes(notificationsOpen),
  };

  const [state, dispatch] = useReducer(reducer, {
    analytics: [],
    bonuses_changes: [],
    payments_changes: [],
    freespins_changes: [],
    lootboxes_changes: [],
    jackpot: [],
    groups_updates_separated_by_type: [],
  });
  const { isLoading, client } = useRealtime();

  useEffect(() => {
    if (!notificationsOpen) return;
    return () => {
      if (
        unreadNotifications[user?.currentUser?.id as any]?.some((el: any) => {
          return (
            el?.includes('withdraw') ||
            el?.includes('level') ||
            el?.includes('deposit')
          );
        })
      ) {
        setUnreadNotifications((prev: any) => {
          const prevNotif = prev[user?.currentUser?.id as any] || [];
          return {
            ...prev,
            [user?.currentUser?.id as any]: prevNotif?.filter(
              (el: any) =>
                !el?.includes('withdraw') &&
                !el?.includes('level') &&
                !el?.includes('deposit'),
            ),
          };
        });
      }
    };
  }, [
    notificationsOpen,
    openNotificationsModal,
    setUnreadNotifications,
    unreadNotifications,
    user?.currentUser?.id,
  ]);

  useEffect(() => {
    if (!notificationsOpen || isLoading || levelLoading || user?.isLoading)
      return;
    if (!client) return;
    client.onmessage = (event) => {
      const data: {
        method: string;
        body: { channel: string; data: any; uid: string };
      } = JSON.parse(event.data);
      if (
        data?.method !== 'message' ||
        data?.body?.channel?.includes('public:wins')
      )
        return;

      const isOpen = JSON.parse(
        localStorage.getItem('openNotifications') as string,
      );
      if (!isOpen) {
        return;
      }
      const { payload, type, chanName } = processUnreadNotification(
        data?.body,
        currentLevel?.level as string,
      );

      if (!payload || !type || type === 'undefined') {
        return;
      }

      if (
        !isNotificationRead(
          `${type}-${payload.id}`,
          unreadNotifications[user?.currentUser?.id as any],
        ) &&
        !unreadNotifications[user?.currentUser?.id as any]?.includes(
          `${type}-${payload.id}`,
        )
      ) {
        setUnreadNotifications((prev: any) => {
          const prevNotif = prev[user?.currentUser?.id as any] || [];
          return {
            ...prev,
            [user?.currentUser?.id as any]: [
              ...prevNotif,
              `${type}-${payload.id}`,
            ],
          };
        });
      }
      dispatch({
        type: 'add_unread',
        channel: chanName,
        payload: { data: payload, uid: data?.body?.uid },
      });
    };
  }, [
    notificationsOpen,
    currentLevel?.level,
    isLoading,
    setUnreadNotifications,
    unreadNotifications,
    levelLoading,
    user?.currentUser?.id,
    user?.isLoading,
    client,
  ]);

  const { data: freeSpins } = playerData.freeSpins;
  const { data: bonuses } = playerData.bonuses;
  const { data: lootboxes } = playerData.lootboxes;
  const { data: payments } = playerData.payments;

  const liveNotifications = useMemo(() => {
    return Object.keys(state)
      .map((key) => state[key])
      .flat()
      .map((item) => item.data);
  }, [state]);

  const notifications = useMemo(() => {
    if (!notificationsOpen) return;
    const unreadNotificationsIds =
      unreadNotifications[user?.currentUser?.id as any]?.map((id: any) => id) ||
      [];
    const freeSpinsNotifications = freeSpins
      ?.filter(
        (freeSpins: any) =>
          freeSpins?.stage === 'issued' && freeSpins?.activatable,
      )
      ?.map((el: any) => mapFreeSpinNotification(el, unreadNotificationsIds));
    const paymentNotifications = payments
      ?.filter(
        (el) =>
          el?.state === 'succeeded' &&
          // only show those from current week
          dayjs(el?.created_at).isAfter(dayjs().startOf('week')),
      )
      .map((el) => mapPaymentNotification(el, unreadNotificationsIds));
    const levelNotifications = state?.groups_updates_separated_by_type?.map(
      (el: any) => mapLevelNotification(el?.data, unreadNotificationsIds),
    );

    const bonusNotifications = bonuses
      ?.filter(
        (bonus: any) =>
          (bonus?.stage === 'issued' ||
            bonus?.stage === 'handle_bets' ||
            bonus?.stage === 'wait') &&
          bonus?.activatable &&
          !bonus?.jackpot?.name,
      )
      ?.map((el: any) => mapBonusNotification(el, unreadNotificationsIds));

    const jackpotNotifications = bonuses
      ?.filter(
        (bonus: any) =>
          (bonus?.stage === 'issued' ||
            bonus?.stage === 'handle_bets' ||
            bonus?.stage === 'wait') &&
          bonus?.activatable &&
          bonus?.jackpot?.name,
      )
      ?.map((el: any) => mapJackpotNotification(el, unreadNotificationsIds));
    const lootboxNotifications = lootboxes
      ?.filter((lootbox: any) => lootbox.stage === 'issued')
      ?.map((el: any) => mapLootboxNotification(el, unreadNotificationsIds));

    const allNotifications = [
      freeSpinsNotifications,
      levelNotifications,
      paymentNotifications,
      bonusNotifications,
      lootboxNotifications,
      jackpotNotifications,
    ]
      .filter(Boolean)
      .flat();

    const sortedAndFilteredNotifications = sortNotificationsByCreatedAtDesc(
      allNotifications?.filter(
        (notification) =>
          !liveNotifications.find((item) => item.id === notification.id),
      ),
    );

    return (
      [
        ...filterNotificationsByType('analytics', state.analytics),
        ...filterNotificationsByType('bonuses_changes', state.bonuses_changes),
        ...filterNotificationsByType(
          'payments_changes',
          state.payments_changes,
        ),
        ...filterNotificationsByType(
          'freespins_changes',
          state.freespins_changes,
        ),
        ...filterNotificationsByType(
          'lootboxes_changes',
          state.lootboxes_changes,
        ),
        ...filterNotificationsByType('jackpot', state.jackpot),
        ...sortedAndFilteredNotifications,
      ]
        // Filter out wheel notifications temporarily
        // .filter(
        //   (notification): notification is NonNullable<typeof notification> =>
        //     notification != null &&
        //     notification.group_key != null &&
        //     !EXCLUDED_NOTIFICATIONS.includes(notification.group_key),
        // )

        .filter(Boolean)
        .flat()
        .sort((a: any, b: any) => a.read - b.read)
        .sort((a: any, b: any) => {
          if (a?.created_at && b?.created_at) {
            return dayjs(a?.created_at).isAfter(dayjs(b?.created_at)) ? -1 : 1;
          }
          return 1;
        })
    );
  }, [
    notificationsOpen,
    unreadNotifications,
    user?.currentUser?.id,
    freeSpins,
    payments,
    state?.groups_updates_separated_by_type,
    state.analytics,
    state.bonuses_changes,
    state.payments_changes,
    state.freespins_changes,
    state.lootboxes_changes,
    state.jackpot,
    bonuses,
    lootboxes,
    liveNotifications,
  ]);

  const notifIds = useMemo(
    () => notifications?.map((notif) => `${notif.type}-${notif.id}`) || [],
    [notifications],
  );

  const isReadLoading =
    playerData?.freeSpins?.isInitialLoading ||
    playerData?.bonuses?.isInitialLoading ||
    playerData?.lootboxes?.isInitialLoading;

  useEffect(() => {
    if (!notificationsOpen) return;
    cleanupNotifications(
      notifIds,
      unreadNotifications[user?.currentUser?.id as any],
      setUnreadNotifications,
      user,
      isReadLoading,
    );
  }, [
    isReadLoading,
    notifIds,
    notificationsOpen,
    setUnreadNotifications,
    unreadNotifications,
    user,
  ]);

  return {
    isLoading: isReadLoading,
    unreadNotifications,
    setUnreadNotifications,
    notifications,
  };
}

export default usePlayerNotifications;
