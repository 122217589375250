import client from '@/utils/api';
import { useQuery } from '@tanstack/react-query';

export const useWildChatInfo = () => {
  return useQuery({
    queryKey: ['playersChatInfo'],
    queryFn: () => client('info/players_chat', {}, 'GET'),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useAvailableRooms = () => {
  return useQuery({
    queryKey: ['guestAvailableRooms'],
    queryFn: fetchGuestAvailableRooms,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useGuestChatMessages = (roomId: string, enabled: boolean) => {
  return useQuery({
    queryKey: ['guestChatMessages', roomId],
    queryFn: async () => fetchGuestMessages(roomId),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
};

type MessageAuthor = {
  id: string;
  chat_name: string;
  avatar_url: string;
  avatar_path: string;
  author_type: string;
};

type ChatMessage = {
  id: string;
  sent_at: string;
  author: MessageAuthor;
  payload: { content: string };
};

type FetchMessagesResponse = {
  messages: ChatMessage[];
};

const fetchMessages = async (
  host: string,
  roomId: string,
  token: string,
): Promise<ChatMessage[]> => {
  const response = await fetch(`${host}/player_api/rooms/${roomId}/messages`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    console.error(
      `Failed to fetch messages for room ${roomId}. Status: ${response.status}`,
    );
  }

  const data: FetchMessagesResponse = await response.json();
  return data.messages;
};

export const useChatMessages = (
  host: string,
  roomId: string,
  token: string,
) => {
  const isEnabled = !!roomId && !!token;

  return useQuery<ChatMessage[], Error>({
    queryKey: ['chatMessages', roomId],
    queryFn: () => fetchMessages(host, roomId, token),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: isEnabled,
  });
};

export const useGuestConnectionToken = () => {
  return useQuery({
    queryKey: ['useGuestConnectionToken'],
    queryFn: fetchGuestConnectionToken,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const fetchGuestMessages = async (roomId: string): Promise<any[]> => {
  const response = await fetch(
    `https://chat.p6m.live/api/rooms/${roomId}/messages`,
    {
      method: 'GET',
    },
  );

  if (!response.ok) {
    // Better error message with response status
    throw new Error(
      `Failed to fetch messages for room ${roomId}. Status: ${response.status}`,
    );
  }

  const data = await response.json();
  return data.messages;
};

const fetchGuestAvailableRooms = async () => {
  try {
    const response = await fetch(
      `https://chat.p6m.live/api/rooms?client_codename=wildcasino`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      },
    );

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(
        `Failed to fetch available rooms: ${response.status} - ${errorMessage}`,
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching guest available rooms:', error);
    throw error;
  }
};

const fetchGuestConnectionToken = async () => {
  try {
    const response = await fetch(
      `https://chat.p6m.live/api/rooms/connection_token`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(
        `Failed to fetch connection token: ${response.status} - ${errorMessage}`,
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching guest connection token:', error);
    throw error;
  }
};
