'use client';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Centrifuge, Subscription, SubscriptionState } from 'centrifuge';
import { useAuth } from '@/hooks/auth';
import {
  useWildChatInfo,
  useAvailableRooms,
  useGuestConnectionToken,
  useChatMessages,
  useGuestChatMessages,
} from '@/modules/wild-chat/hooks/new-hooks';

export type ChatMessage = {
  id: string;
  author: {
    id: string;
    author_type: string;
    avatar_path: string;
    avatar_url: string;
    chat_name: string;
  };
  payload: {
    content: string;
  };
  sent_at: string;
  type?: string;
};

type ChatContextType = {
  messages: ChatMessage[];
  addMessage: (
    content: string,
    roomId?: string,
    type?: string,
  ) => Promise<void>;
  isConnected: boolean;
  isLoading: boolean;
};

export const ChatContext = createContext<ChatContextType | undefined>(
  undefined,
);

type ChatPublicationData =
  | { action: 'create_message'; message: ChatMessage; message_id?: never }
  | { action: 'hide_message'; message?: never; message_id: string };

const ChatProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuth();
  const isAuthenticated = user?.isAuthenticated;

  const { data: wildChatInfo, isLoading: isInfoLoading } = useWildChatInfo();
  const { data: availableRooms, isLoading: isRoomsLoading } =
    useAvailableRooms();
  const { data: connectionToken, isLoading: isTokenLoading } =
    useGuestConnectionToken();

  const centrifugeRef = useRef<Centrifuge | null>(null);
  const subscriptionRef = useRef<Subscription | null>(null);
  const [isConnected, setIsConnected] = useState(false);

  const availableRoomId = useMemo(() => {
    if (!availableRooms || availableRooms.length === 0) return null;
    return availableRooms[0].id;
  }, [availableRooms]);

  const { data: guestMessages } = useGuestChatMessages(
    availableRoomId,
    !!availableRoomId,
  );
  const { data: authMessages } = useChatMessages(
    wildChatInfo?.host,
    availableRoomId,
    wildChatInfo?.access_token,
  );

  // Initialize messages based on auth state
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const initialMessagesRef = useRef(false);

  useEffect(() => {
    if (!availableRoomId) return;
    if (initialMessagesRef.current) return;

    if (isAuthenticated && authMessages?.length) {
      setMessages(authMessages.slice().reverse());
      initialMessagesRef.current = true;
    } else if (!isAuthenticated && guestMessages?.length) {
      setMessages(guestMessages.slice().reverse());
      initialMessagesRef.current = true;
    }
  }, [isAuthenticated, authMessages, guestMessages, availableRoomId]);

  const availableRoomName = useMemo(() => {
    if (!availableRooms || availableRooms.length === 0) return null;
    return availableRooms[0].centrifugo_channel;
  }, [availableRooms]);

  const fetchRoomSettings = useCallback(async () => {
    try {
      const response = await fetch(`${wildChatInfo.host}/player_api/settings`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${wildChatInfo.access_token}`,
        },
      });

      if (!response.ok) {
        console.error('Failed to fetch room settings');
      }

      const data = await response.json();
      return data.rooms?.[0] || {};
    } catch (err) {
      console.error('Error fetching room settings:', err);
    }
  }, [wildChatInfo]);

  useEffect(() => {
    // If we already have a centrifuge instance and the user changed to isAuthenticated,
    // we disconnect (and unsubscribe) so it can re-initialize with the new auth token.
    if (centrifugeRef.current && isAuthenticated) {
      subscriptionRef.current?.unsubscribe();
      subscriptionRef.current = null;
      centrifugeRef.current.disconnect();
      centrifugeRef.current = null;
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isInfoLoading || isRoomsLoading || isTokenLoading) return;
    if (centrifugeRef.current) return;

    const fetchSignedInConnectionToken = async () => {
      const response = await fetch(
        `${wildChatInfo.host}/player_api/rooms/connection_token`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${wildChatInfo.access_token}`,
          },
        },
      );
      if (!response.ok) {
        console.error('Failed to fetch connection token for signed-in player');
      }
      const data = await response.json();
      return data.connection_token;
    };

    if (!wildChatInfo?.ws_host) return;

    const initializeCentrifugo = async () => {
      try {
        const token = isAuthenticated
          ? await fetchSignedInConnectionToken()
          : connectionToken?.connection_token;

        if (!token) {
          console.error('Missing connection token');
          return;
        }

        const subscriptionToken = isAuthenticated
          ? await fetch(
              `${wildChatInfo.host}/player_api/rooms/${(await fetchRoomSettings())?.id}/token`,
              {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  Authorization: `Bearer ${wildChatInfo.access_token}`,
                },
              },
            )
              .then((res) => {
                if (!res.ok) {
                  throw new Error(
                    `Failed to fetch subscription token for signed-in player`,
                  );
                }
                return res.json();
              })
              .then((data) => data.access_token)
          : await fetch(
              `${wildChatInfo.host}/api/rooms/${availableRoomId}/token`,
              {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                },
              },
            )
              .then((res) => {
                if (!res.ok) {
                  throw new Error(
                    `Failed to fetch subscription token for guest player`,
                  );
                }
                return res.json();
              })
              .then((data) => data.access_token);

        centrifugeRef.current = new Centrifuge(
          `${wildChatInfo.ws_host}/connection/websocket`,
          { token },
        );

        centrifugeRef.current.on('connected', async () => {
          setIsConnected(true);

          try {
            let channelName = availableRoomName;
            let roomId: string | null = null;

            if (user?.isAuthenticated) {
              const roomSettings = await fetchRoomSettings();
              if (!roomSettings) {
                return;
              }
              const { centrifugo_channel, id } = roomSettings;
              channelName = centrifugo_channel;
              roomId = id;
            } else {
              roomId = availableRoomId;
            }

            let subscription =
              centrifugeRef?.current?.getSubscription(channelName);

            if (!subscription) {
              subscription = centrifugeRef?.current?.newSubscription(
                channelName,
                {
                  token: subscriptionToken,
                },
              );

              subscription?.on(
                'publication',
                (message: { data: ChatPublicationData }) => {
                  setMessages((prev) => {
                    try {
                      switch (message.data.action) {
                        case 'hide_message':
                          return prev.filter(
                            (msg) => msg.id !== message.data.message_id,
                          );

                        case 'create_message': {
                          const newMsg = message.data.message;
                          if (prev.find((m) => m.id === newMsg.id)) {
                            return prev;
                          }
                          return [...prev, newMsg];
                        }

                        default: {
                          console.error(
                            'Unknown message action:',
                            message.data,
                          );
                          return prev;
                        }
                      }
                    } catch (error) {
                      console.error('Error processing chat message:', error);
                      return prev;
                    }
                  });
                },
              );
            }

            if (subscription?.state !== SubscriptionState.Subscribed) {
              subscription?.subscribe();
            }

            subscriptionRef.current = subscription || null;
          } catch (error) {
            console.error('Error during room subscription:', error);
          }
        });

        centrifugeRef.current.on('disconnected', (context) => {
          console.error('Disconnected from Centrifugo', context);
          setIsConnected(false);
        });

        centrifugeRef.current.connect();
      } catch (err) {
        console.error('Error initializing Centrifugo:', err);
      }
    };

    initializeCentrifugo();

    return () => {
      subscriptionRef.current?.unsubscribe();
      subscriptionRef.current = null;
      centrifugeRef.current?.disconnect();
      centrifugeRef.current = null;
    };
  }, [
    wildChatInfo,
    connectionToken,
    isInfoLoading,
    isRoomsLoading,
    isTokenLoading,
    isAuthenticated,
    fetchRoomSettings,
    availableRoomName,
    user?.isAuthenticated,
    availableRoomId,
  ]);

  const addMessage = useCallback(
    async (content: string, roomId?: string, type: string = 'text') => {
      const targetRoomId = roomId || availableRoomId;

      if (!targetRoomId) {
        console.error('No room ID available to send the message');
        return;
      }

      try {
        const url = isAuthenticated
          ? `${wildChatInfo?.host}/player_api/rooms/${targetRoomId}/messages`
          : `${wildChatInfo?.host}/api/rooms/${targetRoomId}/messages`;

        const headers: HeadersInit = {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        };

        if (isAuthenticated && wildChatInfo?.access_token) {
          headers.Authorization = `Bearer ${wildChatInfo.access_token}`;
        }

        const response = await fetch(url, {
          method: 'POST',
          headers,
          body: JSON.stringify({
            message: {
              type,
              payload: {
                content,
              },
            },
          }),
        });

        if (!response.ok) {
          console.error(
            `Failed to send message to room ${targetRoomId}. Status: ${response.status}`,
          );
        }

        const data = await response.json();
      } catch (err) {
        console.error('Error sending message:', err);
      }
    },
    [wildChatInfo, isAuthenticated, availableRoomId],
  );

  const memoValue = useMemo(
    () => ({
      messages,
      addMessage,
      isConnected,
      isLoading: isInfoLoading || isRoomsLoading || isTokenLoading,
    }),
    [
      messages,
      addMessage,
      isConnected,
      isInfoLoading,
      isRoomsLoading,
      isTokenLoading,
    ],
  );

  return (
    <ChatContext.Provider value={memoValue}>{children}</ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = React.useContext(ChatContext);
  if (context === undefined) {
    throw new Error(`useChat must be used within a ChatProvider`);
  }
  return context;
};

export default ChatProvider;
