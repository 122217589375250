export const getPlayURL = (game: any, currency: string, lang = 'en') => {
  const newLang: string | null = lang === 'en' ? null : lang;
  if (!game?.currencies?.hasOwnProperty(currency)) return;
  if (newLang) {
    return `https://www.wild.io/${newLang}/games/${game?.identifier}/${game?.currencies[currency]}`;
  }
  return `https://www.wild.io/games/${game?.identifier}/${game?.currencies[currency]}`;
};

export const getCloudflareUrl = (
  url: string,
  highres = false,
  width?: number,
  height?: number,
) => {
  const defaultWidth = width ? width : highres ? '2324' : '32';
  const quality = highres ? '100' : '75';

  const cloudflareUrl = `https://wild.io${url}`;

  return cloudflareUrl;
};
