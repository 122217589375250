import { useQuery } from '@tanstack/react-query';
import client from '@/utils/api';
import { useAuth } from './auth';

const usePlayerCompPoints = () => {
  const {
    user: { currentUser, isLoading: userLoading, isFetching, isAuthenticated },
  } = useAuth();
  return useQuery({
    queryKey: ['playerCompPoints', currentUser],
    queryFn: () => client('player/comp_points'),
    enabled: !userLoading && !isFetching && isAuthenticated ? true : false,
  });
};
const usePlayerCompPointsRates = () => {
  const {
    user: { currentUser, isLoading: userLoading, isAuthenticated },
  } = useAuth();
  return useQuery({
    queryKey: ['playerCompPointsRates', currentUser],
    queryFn: () => client('player/comp_points/rates/freespin'),
    enabled: !userLoading && isAuthenticated ? true : false,
  });
};

export { usePlayerCompPoints, usePlayerCompPointsRates };
