import { User } from '@/types/user';
import { State } from './types';
import waitForLoad from './utils';
import dayjs from 'dayjs';

const domain = 'https://widget.intercom.io';

declare global {
  interface Window {
    Intercom: any;
    intercomSettings: () => void;
  }
}

/* eslint-disable */
const loadScript = (appId: string): boolean => {
  if (window.Intercom) return false;
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', window.intercomSettings);
    } else {
      var d = document;
      var i: any = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args: any) {
        i.q.push(args);
      };
      w.Intercom = i;
      const l = () => {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `${domain}/widget/${appId}`;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode?.insertBefore(s, x);
      };
      l();
    }
  })();
  return true;
};
/* eslint-enable */

const load = ({
  providerKey,
  setState,
  beforeInit = () => undefined,
  onReady = () => undefined,
}: {
  providerKey: string;
  setState: (state: State) => void;
  beforeInit?: () => void;
  onReady?: () => void;
}): boolean => {
  const loaded = loadScript(providerKey);
  // Continue as long as userlike hasn’t already been initialised.
  if (loaded) {
    beforeInit();
    window.Intercom('boot', { app_id: providerKey });
    waitForLoad(
      () => window.Intercom.booted,
      // Allow intercom to complete loading before removing fake widget
      () =>
        setTimeout(() => {
          setState('complete');
          onReady();
        }, 2000),
    );
  }

  return loaded;
};

const open = (): void => window.Intercom('show');

const identify = (user: User, options = {}) => {
  if (user && user?.id) {
    window.Intercom &&
      window.Intercom('update', {
        app_id: 'wnwkh2fl',
        user_id: `${user.id}_Wildcasino`,
        created: dayjs(user.created_at).unix(),
        confirmedAt: user.confirmed_at ? dayjs(user.confirmed_at).unix() : null,
        email: user.email,
        ...options,
      });
    return;
  }
  window.Intercom &&
    window.Intercom('update', {
      app_id: 'wnwkh2fl',
      hide_default_launcher: true,
      ...options,
    });
};

const boot = (user?: User, options = {}) => {
  if (user && user?.id) {
    window.Intercom &&
      window.Intercom('boot', {
        app_id: 'wnwkh2fl',
        hide_default_launcher: true,
        user_id: `${user.id}_Wildcasino`,
        created: dayjs(user.created_at).unix(),
        confirmedAt: user.confirmed_at ? dayjs(user.confirmed_at).unix() : null,
        email: user.email,
        ...options,
      });
    return;
  }
  window.Intercom &&
    window.Intercom('boot', {
      app_id: 'wnwkh2fl',
      hide_default_launcher: true,
      ...options,
    });
};

const update = (data?: any) => {
  window.Intercom && window.Intercom('update', data);
};

const shutdown = () => {
  window.Intercom && window.Intercom('shutdown');
};

export default {
  domain,
  load,
  boot,
  identify,
  update,
  shutdown,
  open,
};
