import { useAuth } from '@/hooks/auth';
import { useLoyaltyLevel } from '@/hooks/loyalty-level';
import { useCallback, useEffect } from 'react';
import { ALL_PRIZES } from '@/modules/wheel-of-fortune/constants';
import { useRealtime } from '@/app/providers/realtime';
import { useTranslation } from '@/app/i18n/client';
import { NotificationsContentType } from '@/types/json-content/notifications';
import { useToast } from '@purposeinplay/utils';
import { useQueryClient } from '@tanstack/react-query';
import { cn } from '@/utils/style';
import { getSnackbarIconStyle } from '@/components/MediaComponent/utils';
import {
  getNotificationIconByType,
  processUnreadNotification,
  Title,
  TOAST_ONLY_CHANNELS,
} from '../utils';

const alreadyIncludesItem = (
  unreadNotifications: any[],
  typeNotif: string,
  id: string,
) => {
  if (
    unreadNotifications === undefined ||
    id === undefined ||
    typeNotif === undefined
  ) {
    return false;
  }
  return unreadNotifications.includes(`${typeNotif}-${id?.toString()}`);
};

export const getToastTitleByType = (
  type: string,
  content: NotificationsContentType['notification_messages'],
) => {
  switch (type) {
    case 'deposit':
      return content?.deposit?.title;
    case 'withdraw':
      return content?.withdraw?.title;
    case 'level':
      return content?.level?.title;
    case 'freeSpins':
      return content?.freeSpins?.title;
    case 'bonus':
      return content?.bonus?.title;
    case 'lootboxes':
      return content?.lootboxes?.title;
    default:
      return content?.default?.title;
  }
};

export const useNotificationsToasts = (
  unreadNotifications: any,
  setUnreadNotifications: any,
  onToastClick?: (notif: any, type: string) => void,
) => {
  const { user } = useAuth();
  const { toast } = useToast();
  const { client, isLoading } = useRealtime();
  const { currentLevel, isLoading: levelLoading } = useLoyaltyLevel();
  const { t } = useTranslation();
  const notifContent = t('notifications') as NotificationsContentType;
  const content = notifContent.notification_messages;
  const queryClient = useQueryClient();

  const handleToastNotification = useCallback(
    (notif: any, type: string) => {
      if (type === 'undefined') return;
      const matchedPrize = ALL_PRIZES.find((prize) => prize.id === notif.title);
      const { amount_cents, currency, name, freespins_total } = notif;
      if (matchedPrize) {
        return;
      }
      toast({
        title: getToastTitleByType(type, content),
        description: (
          <Title
            type={type}
            message={freespins_total || amount_cents}
            currency={currency}
            info={name}
            viewFiat={false}
            content={content}
          />
        ),
        autoClose: 3000,
        // @ts-ignore
        leadingItem: (
          <div
            className={cn([
              'relative flex h-8 min-w-8 items-center justify-center rounded-radius-md p-padding-sm',
              getSnackbarIconStyle(type === 'level' ? 'info' : 'success'),
            ])}
          >
            {getNotificationIconByType(type)}
          </div>
        ),
        onClick: () => onToastClick && onToastClick(notif, type),
      });
    },
    [content, onToastClick, toast],
  );

  const handleNotificationCallback = useCallback(
    (message: any) => {
      const openNotifications = localStorage.getItem('openNotifications');

      if (openNotifications === 'true') {
        return;
      }

      if (
        message?.channel?.includes('comps_award') ||
        message?.channel?.includes('wins')
      ) {
        return;
      }
      const { payload: notif, type: typeNotif } = processUnreadNotification(
        message,
        currentLevel?.level as string,
      );

      if (
        !notif ||
        alreadyIncludesItem(
          unreadNotifications[user?.currentUser?.id as any],
          typeNotif,
          notif.id,
        )
      ) {
        return;
      }
      if (!TOAST_ONLY_CHANNELS.includes(typeNotif))
        setUnreadNotifications((prevState: any) => {
          return {
            ...prevState,
            [user?.currentUser?.id as any]: [
              ...(prevState[user?.currentUser?.id as any] || []),
              `${typeNotif}-${notif?.id?.toString()}`,
            ],
          };
        });

      handleToastNotification(notif, typeNotif);
      typeNotif === 'freeSpins' &&
        queryClient.invalidateQueries({ queryKey: ['playerFreeSpins'] });
      typeNotif === 'bonus' &&
        queryClient.invalidateQueries({ queryKey: ['playerBonuses'] });
      typeNotif === 'lootboxes' &&
        queryClient.invalidateQueries({ queryKey: ['playerLootboxes'] });
      queryClient.invalidateQueries({ queryKey: ['playerFreeSpins'] });
    },
    [
      currentLevel?.level,
      handleToastNotification,
      queryClient,
      setUnreadNotifications,
      unreadNotifications,
      user?.currentUser?.id,
    ],
  );

  useEffect(() => {
    if (isLoading || levelLoading || user?.isLoading) {
      return;
    }
    if (!client) return;
    client.onmessage = (event) => {
      const data: {
        method: string;
        body: { channel: string; data: any; uid: string };
      } = JSON.parse(event.data);

      if (data?.method !== 'message') return;
      if (data?.body?.channel?.includes('comps_award')) {
        queryClient.setQueryData(['playerCompPoints'], data?.body?.data);
        queryClient.invalidateQueries({ queryKey: ['playerCompPoints'] });
      }
      handleNotificationCallback(data?.body);
    };
  }, [
    client,
    handleNotificationCallback,
    isLoading,
    levelLoading,
    queryClient,
    user?.isLoading,
  ]);
};
