import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { setIsSupportOpen } from '@/app/reducers/bootstrap';
import { useChat } from '@/services/live-chat/use-chat';

export const useSupportChatToggle = () => {
  const shouldShow = false;
  const [state, loadChat] = useChat({ loadWhenIdle: false });
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.bootstrap.isSupportOpen);

  const handleClick = (event: React.MouseEvent) => {
    shouldShow ? dispatch(setIsSupportOpen(!isOpen)) : loadChat({ open: true });
  };

  return { handleClick };
};
