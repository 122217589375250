import { useMemo } from 'react';
import { useGroups } from './groups';
import { useAuth } from './auth';
import { usePlayerCompPoints } from './comp-points';

const MAX_LEVEL_OBJ = {
  label: 'Level 30',
  level: '30',
  points: 50000000,
  nextLevel: 'Level 30',
  progress: 100,
  neededUntilNextLvl: 0,
  maxLevelPoints: 50000000,
};

export interface Level {
  label: string | undefined;
  level: string | undefined;
  points: number;
  nextLevel: string | undefined;
  progress: number;
  neededUntilNextLvl: number;
  maxLevelPoints: number;
}

export const useLoyaltyLevel = () => {
  const { user } = useAuth();
  const { data: groups = [], isLoading: groupsLoading } = useGroups();
  const { data: compPoints, isLoading: compPointsLoading } =
    usePlayerCompPoints();

  const loyaltyGroups = groups.filter((group) =>
    group.id.includes('loyalty:lvl'),
  );

  const userTier = useMemo(() => {
    if (!compPointsLoading && compPoints) {
      // if user has no points or has 0 points
      if (!compPoints?.persistent || compPoints?.persistent?.points === 0) {
        return loyaltyGroups.find((group) => group.id === 'loyalty:lvl1');
      }
      // if user has max points
      if (compPoints?.persistent?.points === 50000000) {
        return loyaltyGroups.find((group) => group.id === 'loyalty:lvl30');
      }
      const usersTier =
        loyaltyGroups &&
        loyaltyGroups.find(
          (group: any) =>
            compPoints.persistent.points >=
              group?.conditions[0]?.persistent_comp_points.min &&
            compPoints.persistent.points <=
              group?.conditions[0]?.persistent_comp_points.max,
        );
      return usersTier;
    }
  }, [compPoints, compPointsLoading, loyaltyGroups]);
  const currentLevel = useMemo((): Level | null => {
    if (!user?.isAuthenticated) return null;
    // handle max level case
    if (userTier?.id === 'loyalty:lvl30') {
      return MAX_LEVEL_OBJ;
    }
    const lvl = loyaltyGroups.find((group) => group?.id === userTier?.id);
    const group = userTier?.conditions[0].persistent_comp_points;
    const currentPoints = compPoints?.persistent?.points || 0;
    const progress = parseFloat(
      (
        (100 * (currentPoints - group?.min)) /
        (group?.max - group?.min)
      ).toFixed(2),
    );
    const level = lvl?.id.split('loyalty:lvl')[1];
    const nextLevel = loyaltyGroups?.find((group) => {
      if (
        group.id.includes('loyalty:lvl') &&
        Number(group.id.split('loyalty:lvl')[1]) === Number(level) + 1
      ) {
        return group;
      }
    })?.name;
    const neededUntilNextLvl = group?.max - currentPoints;

    const maxLevelPoints = group?.max;
    return {
      label: lvl?.name,
      level,
      nextLevel,
      points: currentPoints,
      neededUntilNextLvl,
      progress,
      maxLevelPoints,
    };
  }, [
    userTier,
    user?.isAuthenticated,
    loyaltyGroups,
    compPoints?.persistent?.points,
  ]);

  return { currentLevel, isLoading: groupsLoading || compPointsLoading };
};
