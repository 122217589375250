'use client';
import { useContext, useCallback, useEffect } from 'react';

import { State } from './types';
import { LiveChatLoaderContext } from './context';
import * as Providers from './providers';

const connection =
  typeof window !== 'undefined'
    ? window.navigator && (window.navigator as any).connection
    : null;

export const useChat = (
  {
    loadWhenIdle,
  }: {
    loadWhenIdle: boolean;
  } = { loadWhenIdle: false },
): [State, ({ open }: { open: boolean }) => void] => {
  const {
    provider,
    providerKey,
    idlePeriod,
    state,
    setState,
    appID,
    locale,
    baseUrl,
    beforeInit,
    onReady,
  } = useContext(LiveChatLoaderContext);

  const chatProvider = Providers[provider];

  const loadChat = useCallback<(args: { open: boolean }) => void>(
    ({ open = true } = { open: true }) => {
      if (!providerKey) {
        //eslint-disable-next-line no-console
        console.error('No api key given');
        return;
      }

      if (!provider) {
        //eslint-disable-next-line no-console
        console.error('No provider given');
        return;
      }

      chatProvider.load({
        providerKey,
        setState,
        // @ts-ignore
        appID,
        locale,
        baseUrl,
        beforeInit,
        onReady,
      });
      if (open) {
        chatProvider.open();
        if (state !== 'complete') setState('open');
      }
    },
    [
      appID,
      baseUrl,
      beforeInit,
      chatProvider,
      locale,
      onReady,
      provider,
      providerKey,
      setState,
      state,
    ],
  );

  useEffect(() => {
    // Don't load if idlePeriod is 0, null or undefined
    if (typeof window === 'undefined' || !loadWhenIdle || !idlePeriod) return;

    // Don't load if 2g connection or save-data is enabled
    if (
      connection &&
      (connection.saveData || /2g/.test(connection.effectiveType))
    )
      return;

    setTimeout(() => loadChat({ open: false }), idlePeriod);
  }, [idlePeriod, loadChat, loadWhenIdle]);

  return [state, loadChat];
};
